import React from "react";
import Link from "next/link";
import { LinkProps } from "next/link";
interface CustomLinkProps extends LinkProps {
  title?: string;
  target?: string;
  className?: string;
}

const CustomLink: React.FC<CustomLinkProps> = ({
  children,
  title,
  className,
  ...others
}) => {
  return (
    <Link {...others} title={title} className={className}>
      {children}
    </Link>
  );
};

export default CustomLink;
