import React from "react";
import CustomLink from "components/custom/CustomLink";
import { useRouter } from "next/router";
const HomeBannerSvgComponent: React.FC = (
  props: React.SVGProps<SVGSVGElement>
) => {
  const { country } = useRouter().query;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100%"
      height="100%"
      viewBox="0 0 674 378.624"
      {...props}
    >
      <g data-name="Group 485">
        <g data-name="Group 484">
          <g data-name="Group 445">
            <g data-name="Group 438">
              <path
                data-name="Path 1560"
                d="M235.231 211.839l-1.361-17.322-63.507-5.327-11.456-22.658h-32.213a13.2 13.2 0 00-12.5 17.391l11.131 33.15a13.2 13.2 0 0012.509 8.994h84.247a13.2 13.2 0 0013.15-14.228z"
                fill="#2de3ec"
              />
              <path
                data-name="Path 1561"
                d="M233.874 194.517l-63.507-5.327-11.456-22.658h-29.063a13.1 13.1 0 00.5 6.455l11.131 33.15a13.194 13.194 0 0012.509 8.994h81.076a13.264 13.264 0 00.166-3.288z"
                fill="#2de3ec"
              />
              <path
                data-name="Path 1562"
                d="M213.187 238.031h-64.454l-5.948-11.884h76.35z"
                fill="#01adef"
              />
              <path
                data-name="Rectangle 392"
                fill="#2de3ec"
                d="M169.407 238.032h23.106v114.525h-23.106z"
              />
              <path
                data-name="Path 1563"
                d="M212.127 352.552h-62.334c-17.966 0-32.53 11.62-32.53 25.954h127.393c0-14.329-14.563-25.954-32.529-25.954z"
                fill="#38324c"
              />
              <path
                data-name="Path 1564"
                d="M225.954 295.957h-89.988a4.423 4.423 0 000 8.845h89.988a4.423 4.423 0 000-8.845z"
                fill="#ef7b99"
              />
              <path
                data-name="Path 1565"
                d="M225.954 295.957h-89.988a4.423 4.423 0 000 8.845h89.988a4.423 4.423 0 000-8.845z"
                fill="#01adef"
              />
            </g>
            <g data-name="Group 442">
              <path
                data-name="Path 1566"
                d="M167.215 39.746s-11.182-20.626-17.6-16.391.683 7.909.683 7.909-22.676 1.281-20.764 7.681 12.846 4.079 12.365 4.216-16.6 15.922-9.087 18.889 14.479-3.374 14.479-3.374-8.2 12.251-.683 12.574 15.887-8.128 15.887-8.128-1.992-22.557 4.72-23.376z"
                fill="#38324c"
              />
              <g data-name="Group 439" fill="#01adef">
                <path
                  data-name="Path 1567"
                  d="M197.42 56.923a8.066 8.066 0 016.687 9.244l-.14.876a8.068 8.068 0 01-9.245 6.687 4.014 4.014 0 01-3.326-4.6l1.426-8.882a4.014 4.014 0 014.598-3.325z"
                />
                <path
                  data-name="Path 1568"
                  d="M199.745 57.669a12.5 12.5 0 00-.761 1.817 52.328 52.328 0 00-2.251 9.105.987.987 0 01-.3.684c-.337.243-.81-.033-1.065-.361a4.809 4.809 0 01-.7-3.2 22.963 22.963 0 011.643-7.558 10.886 10.886 0 01.6-1.275 3.8 3.8 0 01.505.046 8.042 8.042 0 012.329.742z"
                  stroke="#fff"
                  strokeMiterlimit={10}
                  strokeWidth={0.5}
                />
              </g>
              <path
                data-name="Path 1569"
                d="M190.243 39.998c.731-.107 15.646-2.518 20.28.336s-4.916 7.671-4.916 7.671 8.173 7.577 4.077 12.278-7.5-.611-7.5-.611.769 10.686-4.821 9.865-7.12-29.539-7.12-29.539z"
                fill="#38324c"
              />
              <path
                data-name="Path 1570"
                d="M234.849 283.522l6.026 20.5s-17.8-12.389-24.746-20.16l8.712-8.495z"
                fill="#01adef"
              />
              <path
                data-name="Path 1571"
                d="M196.46 162.655s64.116 24.034 77.4 26.567 12.4 15.9 10.226 22.383-48.218 71.899-48.218 71.899-9.7 2.789-15.443-4.609l24.173-58.535s-51.322-.938-64.284-10.072-12.862-29.716-12.862-29.716 18.59-18.214 29.008-17.917z"
                fill="#38324c"
              />
              <path
                data-name="Path 1572"
                d="M249.978 222.278s1.305-10.484 9.648-14.578"
                fill="none"
                stroke="#fff"
                strokeMiterlimit={10}
                strokeWidth={0.5}
              />
              <path
                data-name="Path 1573"
                d="M251.557 206.284s-4.383 5.878-3.249 13.015"
                fill="none"
                stroke="#fff"
                strokeMiterlimit={10}
                strokeWidth={0.5}
              />
              <path
                data-name="Path 1574"
                d="M182.925 352.559l4.946 20.789s-17.131-13.3-23.658-21.426l9.144-8.028z"
                fill="#01adef"
              />
              <path
                data-name="Path 1575"
                d="M165.444 70.613c-.223.127.9 11.055-.569 16.965 0 0 17.41 2.373 16.939 2.21s-.076-12.826-.076-12.826-11.432-9.115-16.294-6.349z"
                fill="#eeb2a8"
              />
              <path
                data-name="Path 1576"
                d="M165.444 70.613a16.693 16.693 0 00.052 2.5c2.545 3.753 7.854 10 16.086 12.255.009-3.709.155-8.4.155-8.4s-11.43-9.121-16.293-6.355z"
                fill="#eeb2a8"
              />
              <path
                data-name="Path 1577"
                d="M198.352 57.578s-6.8 26.183-14.339 24.968c-11.14-1.8-18.307-9.838-19.8-14.612 0 0-3.442-28.545 19.7-26.294 18.068 1.759 14.439 15.938 14.439 15.938z"
                fill="#eeb2a8"
              />
              <path
                data-name="Path 1578"
                d="M196.145 46.951c-.6 1.329-11.972 3.723-20.374 5.157s-5.165 10.861-8.269 12.451c-2.627 1.346-1.855 13.981-1.855 13.981a32.688 32.688 0 01-.863-31.589c8.734-17.176 18.4-14.1 20.022-18.816s2.979-10.859 2.979-10.859 10.417 2.459 6.373 14.138c0 0 20.731-8.4 14.9.615a81.737 81.737 0 01-12.913 14.922z"
                fill="#38324c"
              />
              <path
                data-name="Path 1579"
                d="M175.053 34.327c.358-.081-12.108 10.619-9.639 19.937h-5.215s-1.641-16.205 14.854-19.937z"
                fill="#fff"
              />
              <path
                data-name="Path 1580"
                d="M178.093 54.989a4.745 4.745 0 016.223-.469"
                fill="none"
                stroke="#38324c"
                strokeMiterlimit={10}
                strokeWidth={0.5}
              />
              <path
                data-name="Path 1581"
                d="M192.171 55.205s3.424-.834 3.974 1.667"
                fill="none"
                stroke="#38324c"
                strokeMiterlimit={10}
                strokeWidth={0.5}
              />
              <path
                data-name="Path 1582"
                d="M181.689 59.003c-.155.963-.754 1.668-1.337 1.575s-.929-.951-.773-1.915.754-1.668 1.336-1.574.928.951.774 1.914z"
                fill="#38324c"
              />
              <path
                data-name="Path 1583"
                d="M194.435 61.057c-.155.963-.754 1.668-1.336 1.575s-.929-.951-.774-1.915.754-1.668 1.337-1.574.924.951.773 1.914z"
                fill="#38324c"
              />
              <path
                data-name="Path 1584"
                d="M189.014 61.425s-2.046 4.842 1.917 4.842c0 0-2.12 1.705-3.963 1.322"
                fill="none"
                stroke="#38324c"
                strokeMiterlimit={10}
                strokeWidth={0.5}
              />
              <path
                data-name="Path 1585"
                d="M181.827 69.968s1.505 3.014 5.958 2.519"
                fill="none"
                stroke="#38324c"
                strokeMiterlimit={10}
                strokeWidth={0.5}
              />
              <g data-name="Group 440">
                <path
                  data-name="Path 1586"
                  d="M225.888 73.427s.085-11.483 2.876-12.988 2.664.375 2.664.375l-1.349 12.1z"
                  fill="#eeb2a8"
                />
                <path
                  data-name="Path 1587"
                  d="M220.746 86.732c-.015-.521-1.464-6.21-.1-9.488a4.434 4.434 0 014.772-2.687s1-3.923 4.363-2.494c0 0 3.7-3.187 5.232.509 0 0 4.088-.515 3.144 4.907s-.767 8.788-9.713 14.4z"
                  fill="#eeb2a8"
                />
                <path
                  data-name="Path 1588"
                  d="M226.929 82.237c.614-3.624-1.207-4.253-1.207-4.253 3.222 2.419 6.339-.566 6.339-.566-2.894-.418-6.645-2.865-6.645-2.865"
                  fill="none"
                  stroke="#eeb2a8"
                  strokeMiterlimit={10}
                  strokeWidth={0.5}
                />
                <path
                  data-name="Path 1589"
                  d="M229.778 72.06a6.5 6.5 0 00-1.849 3.906"
                  fill="none"
                  stroke="#eeb2a8"
                  strokeMiterlimit={10}
                  strokeWidth={0.5}
                />
                <path
                  data-name="Path 1590"
                  d="M235.011 72.569s-3.47 1.9-3.809 4.671"
                  fill="none"
                  stroke="#eeb2a8"
                  strokeMiterlimit={10}
                  strokeWidth={0.5}
                />
                <path
                  data-name="Path 1591"
                  d="M235.284 78.99s-1.789 4.383-3.2 3.686-1.227-4.414-1.227-4.414"
                  fill="none"
                  stroke="#eeb2a8"
                  strokeMiterlimit={10}
                  strokeWidth={0.5}
                />
                <path
                  data-name="Path 1592"
                  d="M227.97 84.699c.121-.387 2.339-2.483 6.579-2.137"
                  fill="none"
                  stroke="#eeb2a8"
                  strokeMiterlimit={10}
                  strokeWidth={0.5}
                />
              </g>
              <path
                data-name="Path 1593"
                d="M192.969 91.696s12.443 2.009 15.617 11.659 5.459 10.157 5.459 10.157 3.3-24.525 6.8-27.7c3.381-3.063 8.867-2.5 12.216 2.7 0 0 2.22 30.88-5.135 45.655-7.616 15.3-15.788 6.39-16.17 5.755s-21.329-19.425-21.329-19.425z"
                fill="#01adef"
              />
              <path
                data-name="Path 1594"
                d="M214.045 113.513a29.852 29.852 0 00-.646 13.641"
                fill="none"
                stroke="#38324c"
                strokeMiterlimit={10}
                strokeWidth={0.5}
              />
              <path
                data-name="Path 1595"
                d="M209.768 127.153a18.61 18.61 0 013.552-12.294"
                fill="none"
                stroke="#38324c"
                strokeMiterlimit={10}
                strokeWidth={0.5}
              />
              <path
                data-name="Path 1596"
                d="M128.148 100.468s-1.968-15.26 12.406-15.157 49.754 5.39 49.754 5.39 11.3 1.268 9.656 11.449.163 63.265.163 63.265-25.755 14.39-51 6.638z"
                fill="#01adef"
              />
              <path
                data-name="Path 1597"
                d="M147.121 165.202s-4.822 32.354 11.246 53.566c18.741 24.741 45.114 38.094 45.114 38.094s-31.424 87.266-30.817 87.816 3.953 10.5 13.375 11.24l54.9-98.3s8.144-10.242-5.782-23.222c-6.158-5.74-33.668-41.427-47.252-58.164-1.442-1.776 4.477-8.413 3.391-9.7-8.192-9.747-44.175-1.33-44.175-1.33z"
                fill="#38324c"
              />
              <path
                data-name="Path 1598"
                d="M172.664 344.678c.607.55 3.953 10.5 13.375 11.24l54.9-98.3s8.144-10.242-5.782-23.222c-4.509-4.2-20.472-24.47-34.028-41.59a13.594 13.594 0 00-2.83-15.808"
                fill="none"
                stroke="#fff"
                strokeMiterlimit={10}
                strokeWidth={0.5}
              />
              <path
                data-name="Path 1599"
                d="M150.224 154.914s3.032-8.067 11.3-9.538"
                fill="none"
                stroke="#000"
                strokeMiterlimit={10}
              />
              <path
                data-name="Path 1600"
                d="M212.431 158.87s12.061 2.285 13.891 8.016.946 7.127-.156 7.061-5.08-1.714-5.746-2.706-11.21-1.2-14.481-3.878 6.492-8.493 6.492-8.493z"
                fill="#eeb2a8"
              />
              <path
                data-name="Path 1601"
                d="M128.15 100.462s-1.515-13.147 5.19-14.141 25.5 27.752 28.187 59.05c0 0 37.366 1.816 51.109 10.848 0 0 5.453 6.4-.613 13.509 0 0-65.336 2.442-70.195-8.342-6.114-13.565-15.231-44.263-13.678-60.924z"
                fill="#01adef"
              />
              <path
                data-name="Path 1602"
                d="M145.726 98.205a110.868 110.868 0 0115.8 47.173s34.626 1.985 50.364 10.219a4.745 4.745 0 011.951 2.718"
                fill="none"
                stroke="#38324c"
                strokeMiterlimit={10}
                strokeWidth={0.5}
              />
              <path
                data-name="Path 1603"
                d="M188.644 92.996a4.849 4.849 0 00-.321-2.582c-4.709-.682-17.035-2.421-28.7-3.679a4.616 4.616 0 00-.529 1.5c-.715 4.432 5.32 9.09 13.48 10.405s15.355-1.218 16.07-5.644z"
                fill="#2de3ec"
              />
              <path
                data-name="Path 1604"
                d="M172.862 96.87c7.666 1.236 13.54-1.2 14.017-4.163a3.707 3.707 0 00-.757-2.613c-5.319-.756-14.953-2.078-24.468-3.141a3.134 3.134 0 00-.792 1.561c-.477 2.96 4.334 7.12 12 8.356z"
                fill="#eeb2a8"
              />
              <path
                data-name="Path 1605"
                d="M147.531 153.752s3.585-6.68 14.531-8.352"
                fill="none"
                stroke="#38324c"
                strokeMiterlimit={10}
                strokeWidth={0.5}
              />
              <path
                data-name="Path 1606"
                d="M203.481 256.863a13.8 13.8 0 0110.13-7.812"
                fill="none"
                stroke="#fff"
                strokeMiterlimit={10}
                strokeWidth={0.5}
              />
              <path
                data-name="Path 1607"
                d="M199.719 104.287c-.034.4-.394 25.539-.394 25.539"
                fill="none"
                stroke="#38324c"
                strokeMiterlimit={10}
                strokeWidth={0.5}
              />
              <g data-name="Group 441" fill="#01adef">
                <path
                  data-name="Path 1608"
                  d="M165.425 51.084a9.8 9.8 0 00-9.8 9.8v1.078a9.8 9.8 0 009.8 9.8 4.877 4.877 0 004.876-4.877V55.961a4.877 4.877 0 00-4.876-4.877z"
                />
                <path
                  data-name="Path 1609"
                  d="M162.494 51.53a15.251 15.251 0 01.563 2.326 63.59 63.59 0 01.947 11.358 1.2 1.2 0 00.227.878c.357.357.978.116 1.348-.228a5.839 5.839 0 001.454-3.708 27.931 27.931 0 00-.516-9.383 13.275 13.275 0 00-.477-1.645 4.8 4.8 0 00-.616-.043 9.814 9.814 0 00-2.93.445z"
                  stroke="#fff"
                  strokeMiterlimit={10}
                  strokeWidth={0.5}
                />
              </g>
            </g>
            <g data-name="Group 444" transform="translate(209.707 125.257)">
              <rect
                data-name="Rectangle 393"
                width={118.712}
                height={16.649}
                rx={7.665}
                transform="translate(0 30.964)"
                fill="#6d8899"
              />
              <path
                data-name="Path 1610"
                d="M91.586 59.497H27.129l-5.943-11.884h76.35z"
                fill="#01adef"
              />
              <path
                data-name="Rectangle 394"
                fill="#6d8899"
                d="M47.803 59.497h23.106v171.164H47.803z"
              />
              <path
                data-name="Path 1611"
                d="M84.419 230.661H34.294a22.591 22.591 0 00-22.591 22.591h95.307a22.592 22.592 0 00-22.591-22.591z"
                fill="#ef7b99"
              />
              <path
                data-name="Path 1612"
                d="M84.419 230.661H34.294a22.591 22.591 0 00-22.591 22.591h95.307a22.592 22.592 0 00-22.591-22.591z"
                fill="#6d8899"
              />
              <g data-name="Group 443">
                <path
                  data-name="Path 1613"
                  d="M75.88 0H40.403L19.284 30.964h41.643L78.571 5.091A3.258 3.258 0 0075.88 0z"
                  fill="#fff"
                />
                <path
                  data-name="Path 1614"
                  d="M43.098 5.091L25.45 30.96h-6.166L40.4-.003h.009a3.257 3.257 0 012.689 5.094z"
                  fill="#38324c"
                />
                <path
                  data-name="Path 1615"
                  d="M56.69 21.31H39.321a1.844 1.844 0 00-1.416 3.024l5.534 6.63h21.31z"
                  fill="#38324c"
                />
                <path
                  data-name="Path 1616"
                  d="M68.439 30.965H47.13l-8.059-9.655H60.38z"
                  fill="#fff"
                />
                <path
                  data-name="Path 1617"
                  d="M39.07 21.309h21.309l8.059 9.655"
                  fill="none"
                  stroke="#38324c"
                  strokeMiterlimit={10}
                  strokeWidth={0.5}
                />
              </g>
            </g>
          </g>
          <g data-name="Group 446">
            <path
              data-name="Rectangle 395"
              fill="#2de3ec"
              d="M446.987 123.188l.768-.768 17.288 17.288-.768.768z"
            />
          </g>
          <g data-name="Group 452">
            <g data-name="Group 449">
              <g data-name="Group 447">
                <path
                  data-name="Path 1618"
                  d="M355.664 141.456h-70.307v-1.086h69.857l15.454-15.454.768.768z"
                  fill="#2de3ec"
                />
              </g>
              <g data-name="Group 448">
                <path
                  data-name="Path 1619"
                  d="M360.895 185.307l-35.187-35.187h-44.033v-1.089h44.482l35.506 35.5z"
                  fill="#2de3ec"
                />
              </g>
            </g>
            <g data-name="Group 450">
              <path
                data-name="Path 1620"
                d="M373.623 123.818a1.69 1.69 0 11-1.69-1.69 1.691 1.691 0 011.69 1.69z"
                fill="#2de3ec"
              />
            </g>
            <g data-name="Group 451">
              <circle
                data-name="Ellipse 20"
                cx={1.69}
                cy={1.69}
                r={1.69}
                transform="translate(360.526 184.075)"
                fill="#2de3ec"
              />
            </g>
          </g>
          <g data-name="Group 453">
            <path
              data-name="Path 1621"
              d="M467.158 140.968a1.69 1.69 0 11-1.69-1.69 1.691 1.691 0 011.69 1.69z"
              fill="#2de3ec"
            />
          </g>
          <g data-name="Group 454">
            <path
              data-name="Path 1622"
              d="M448.017 121.917a1.69 1.69 0 11-1.69-1.691 1.689 1.689 0 011.69 1.691z"
              fill="#2de3ec"
            />
          </g>
          <g data-name="Group 455">
            <path
              data-name="Path 1623"
              d="M61.853 223.222s4.864-37.948 18.487-49.623 28.7-27.244 32.6-22.866-7.3 22.38-8.271 26.272-15.568 45.245-28.7 50.6-14.116-4.383-14.116-4.383z"
              fill="#01adef"
            />
            <path
              data-name="Path 1624"
              d="M42.879 241.222s-5.838-22.866-17.028-30.163-18-21.407-21.893-20.433-5.837 11.676-1.46 17.028 9.731 31.623 17.514 39.893 21.407 18.974 28.218 14.595-5.351-20.92-5.351-20.92z"
              fill="#01adef"
            />
            <path
              data-name="Path 1625"
              d="M82.389 277.961s-1.764-13.456 3.18-20.7 18.11-13.615 22.344-17.424 15.4-12.522 15.337-6.2-8.708 16.111-10.493 22.08-3.362 20.249-13.468 23.807-16.9-1.563-16.9-1.563z"
              fill="#01adef"
            />
            <path
              data-name="Path 1626"
              d="M44.339 286.954s-10.217-15.081-20.433-18-15.082.487-11.189 6.325 14.6 20.432 18.974 24.811 16.055 8.758 18.973 2.919-6.325-16.055-6.325-16.055z"
              fill="#01adef"
            />
            <path
              data-name="Path 1627"
              d="M6.877 200.356s16.541 23.353 19.947 30.65 28.217 34.542 30.65 48.65 4.379 42.813 4.379 42.813"
              fill="none"
              stroke="#38324c"
              strokeMiterlimit={10}
              strokeWidth={0.5}
            />
            <path
              data-name="Path 1628"
              d="M18.553 272.846s20.433 20.433 25.3 27.73 6.324 19.947 6.324 19.947"
              fill="none"
              stroke="#38324c"
              strokeMiterlimit={10}
              strokeWidth={0.5}
            />
            <path
              data-name="Path 1629"
              d="M102.719 160.463s-12.162 16.055-14.6 21.892-18.487 34.055-20.433 42.327 0 96.328 0 96.328"
              fill="none"
              stroke="#38324c"
              strokeMiterlimit={10}
              strokeWidth={0.5}
            />
            <path
              data-name="Path 1630"
              d="M113.909 240.25s-11.19 14.108-14.11 18-14.6 18-18 26.757-7.783 34.055-7.783 34.055"
              fill="none"
              stroke="#38324c"
              strokeMiterlimit={10}
              strokeWidth={0.5}
            />
            <path
              data-name="Path 1631"
              d="M85.888 378.624h-42.39a4.952 4.952 0 01-4.912-4.325l-7.428-58.066a4.952 4.952 0 014.912-5.58h57.245a4.952 4.952 0 014.912 5.58L90.8 374.297a4.952 4.952 0 01-4.912 4.327z"
              fill="#38324c"
            />
            <path
              data-name="Line 2"
              fill="none"
              stroke="#38324c"
              strokeMiterlimit={10}
              strokeWidth={0.5}
              d="M38.586 369.955h52.215"
            />
          </g>
          {/* Project Management Path */}
          <CustomLink
            title="Project Management"
            href={{
              pathname: country
                ? "/[country]/courses/[category]"
                : "/courses/[category]",
              query: {
                category: "project-management",
                ...(country ? { country: country } : {}),
              },
            }}
          >
            <path
              data-name="Path 1632"
              d="M467.578 70.951a61.015 61.015 0 00-30.509-52.842 61.019 61.019 0 00-61.017 0 61.018 61.018 0 00-30.508 52.842 61.019 61.019 0 0030.508 52.843 61.019 61.019 0 0061.017 0 61.016 61.016 0 0030.509-52.843z"
              fill="#2de3ec"
            />
          </CustomLink>
          <path
            data-name="Path 1633"
            d="M468.308 105.685V35.227L407.291-.001l-61.017 35.228v70.457l56.5 32.621a9.03 9.03 0 009.032 0z"
            fill="none"
          />
          {/* IT Security Path */}
          <CustomLink
            title="Cyber Security"
            href={{
              pathname: country
                ? "/[country]/courses/[category]"
                : "/courses/[category]",
              query: {
                category: "cybersecurity",
                ...(country ? { country: country } : {}),
              },
            }}
          >
            <path
              data-name="Path 1634"
              d="M506.125 179.636a33.929 33.929 0 00-16.965-29.386 33.93 33.93 0 00-33.927 0 33.928 33.928 0 00-16.964 29.382 33.928 33.928 0 0016.964 29.382 33.93 33.93 0 0033.927 0 33.929 33.929 0 0016.965-29.378z"
              fill="#01adef"
            />
          </CustomLink>
          {/* DevOps Path */}
          <CustomLink
            title="DevOps"
            href={{
              pathname: country
                ? "/[country]/courses/[category]"
                : "/courses/[category]",
              query: {
                category: "devops",
                ...(country ? { country: country } : {}),
              },
            }}
          >
            <path
              data-name="Path 1635"
              d="M414.807 235.533a47.876 47.876 0 00-23.939-41.459 47.877 47.877 0 00-47.877 0 47.876 47.876 0 00-23.939 41.462 47.876 47.876 0 0023.939 41.462 47.877 47.877 0 0047.877 0 47.876 47.876 0 0023.939-41.465z"
              fill="#01adef"
            />
          </CustomLink>
          {/* Agile Path */}
          <CustomLink
            title="Agile and scrum"
            href={{
              pathname: country
                ? "/[country]/courses/[category]"
                : "/courses/[category]",
              query: {
                category: "agile-and-scrum",
                ...(country ? { country: country } : {}),
              },
            }}
          >
            <path
              data-name="Path 1636"
              d="M630.058 117.42a47.877 47.877 0 00-23.938-41.463 47.877 47.877 0 00-47.877 0 47.876 47.876 0 00-23.938 41.463 47.877 47.877 0 0023.938 41.463 47.878 47.878 0 0047.877 0 47.877 47.877 0 0023.938-41.463z"
              fill="#01adef"
            />
          </CustomLink>
          <path
            data-name="Path 1637"
            d="M415.091 269.969v-55.285l-47.877-27.642-47.878 27.641v55.285l43.571 25.155a8.617 8.617 0 008.613 0z"
            fill="none"
          />
          <g data-name="Group 459">
            <g data-name="Group 456">
              <path
                data-name="Rectangle 396"
                fill="#2de3ec"
                d="M469.807 93.235l.314-1.345 58.1 13.548-.313 1.345z"
              />
            </g>
            <g data-name="Group 457">
              <path
                data-name="Path 1638"
                d="M469.538 94.047a2.147 2.147 0 11.123-3.033 2.146 2.146 0 01-.123 3.033z"
                fill="#2de3ec"
              />
            </g>
            <g data-name="Group 458">
              <path
                data-name="Path 1639"
                d="M531.221 108.124a2.146 2.146 0 11.123-3.033 2.146 2.146 0 01-.123 3.033z"
                fill="#2de3ec"
              />
            </g>
          </g>
          <g data-name="Group 463">
            <g data-name="Group 460">
              <path
                data-name="Rectangle 397"
                fill="#2de3ec"
                d="M394.091 277.931l.356-.719 31.04 15.381-.357.719z"
              />
            </g>
            <g data-name="Group 461">
              <path
                data-name="Path 1640"
                d="M393.831 278.356a1.246 1.246 0 11.471-1.7 1.246 1.246 0 01-.471 1.7z"
                fill="#2de3ec"
              />
            </g>
            <g data-name="Group 462">
              <path
                data-name="Path 1641"
                d="M426.834 294.495a1.246 1.246 0 11.471-1.7 1.246 1.246 0 01-.471 1.7z"
                fill="#2de3ec"
              />
            </g>
          </g>
          <g data-name="Group 467">
            <g data-name="Group 464">
              <path
                data-name="Rectangle 398"
                fill="#2de3ec"
                d="M417.996 226.338L445.64 208.9l.775 1.228-27.644 17.438z"
              />
            </g>
            <g data-name="Group 465">
              <path
                data-name="Path 1642"
                d="M416.505 229.95a2.26 2.26 0 112.7-1.705 2.261 2.261 0 01-2.7 1.705z"
                fill="#2de3ec"
              />
            </g>
            <g data-name="Group 466">
              <path
                data-name="Path 1643"
                d="M446.994 210.618a2.26 2.26 0 112.7-1.706 2.26 2.26 0 01-2.7 1.706z"
                fill="#2de3ec"
              />
            </g>
          </g>
          <g data-name="Group 471">
            <g data-name="Group 468">
              <path
                data-name="Rectangle 399"
                fill="#2de3ec"
                d="M526.569 298.58l31.423-19.822.88 1.396-31.423 19.822z"
              />
            </g>
            <g data-name="Group 469">
              <path
                data-name="Path 1644"
                d="M524.874 302.686a2.568 2.568 0 113.072-1.938 2.569 2.569 0 01-3.072 1.938z"
                fill="#2de3ec"
              />
            </g>
            <g data-name="Group 470">
              <path
                data-name="Path 1645"
                d="M559.531 280.711a2.569 2.569 0 113.073-1.939 2.568 2.568 0 01-3.073 1.939z"
                fill="#2de3ec"
              />
            </g>
          </g>
          <g data-name="Group 475">
            <g data-name="Group 472">
              <path
                data-name="Rectangle 400"
                fill="#2de3ec"
                d="M472.625 217.494l.917-.068 1.542 20.65-.918.068z"
              />
            </g>
            <g data-name="Group 473">
              <path
                data-name="Path 1646"
                d="M475.744 240.053a1.432 1.432 0 11-.151-2.019 1.431 1.431 0 01.151 2.019z"
                fill="#2de3ec"
              />
            </g>
            <g data-name="Group 474">
              <path
                data-name="Path 1647"
                d="M473.988 217.247a1.432 1.432 0 11-.151-2.019 1.431 1.431 0 01.151 2.019z"
                fill="#2de3ec"
              />
            </g>
          </g>
          <g data-name="Group 479">
            <g data-name="Group 476">
              <path
                data-name="Rectangle 401"
                fill="#fff"
                d="M588.529 166.461l.737-.055 1.239 16.598-.737.055z"
              />
            </g>
            <g data-name="Group 477">
              <path
                data-name="Path 1648"
                d="M591.037 184.598a1.151 1.151 0 11-.122-1.623 1.15 1.15 0 01.122 1.623z"
                fill="#fff"
              />
            </g>
            <g data-name="Group 478">
              <path
                data-name="Path 1649"
                d="M589.625 166.263a1.15 1.15 0 11-.121-1.622 1.149 1.149 0 01.121 1.622z"
                fill="#fff"
              />
            </g>
          </g>
          <g data-name="Group 483">
            <g data-name="Group 480">
              <path
                data-name="Rectangle 402"
                fill="#2de3ec"
                d="M509.533 168.302l29.832-18.819.837 1.326-29.833 18.818z"
              />
            </g>
            <g data-name="Group 481">
              <path
                data-name="Path 1650"
                d="M507.923 172.202a2.439 2.439 0 112.917-1.841 2.439 2.439 0 01-2.917 1.841z"
                fill="#2de3ec"
              />
            </g>
            <g data-name="Group 482">
              <path
                data-name="Path 1651"
                d="M540.827 151.332a2.439 2.439 0 112.917-1.84 2.438 2.438 0 01-2.917 1.84z"
                fill="#2de3ec"
              />
            </g>
          </g>
          {/* Quality Management path */}
          <CustomLink
            title="Quality Management"
            href={{
              pathname: country
                ? "/[country]/courses/[category]"
                : "/courses/[category]",
              query: {
                category: "quality-management",
                ...(country ? { country: country } : {}),
              },
            }}
          >
            <path
              data-name="Path 1652"
              d="M674 242.05a61.015 61.015 0 00-30.506-52.839 61.019 61.019 0 00-61.017 0 61.016 61.016 0 00-30.508 52.843 61.017 61.017 0 0030.508 52.842 61.018 61.018 0 0061.017 0A61.016 61.016 0 00674 242.05z"
              fill="#01adef"
            />
          </CustomLink>
          {/* ITSM Path */}
          <CustomLink
            title="ITSM"
            href={{
              pathname: country
                ? "/[country]/courses/[category]"
                : "/courses/[category]",
              query: {
                category: "itsm",
                ...(country ? { country: country } : {}),
              },
            }}
          >
            <path
              data-name="Path 1653"
              d="M524.089 289.623a47.878 47.878 0 00-23.939-41.463 47.877 47.877 0 00-47.877 0 47.878 47.878 0 00-23.939 41.463 47.878 47.878 0 0023.939 41.463 47.877 47.877 0 0047.877 0 47.879 47.879 0 0023.939-41.463z"
              fill="#01adef"
            />
          </CustomLink>
        </g>
        <CustomLink
          title="Agile and scrum"
          href={{
            pathname: country
              ? "/[country]/courses/[category]"
              : "/courses/[category]",
            query: {
              category: "agile-and-scrum",
              ...(country ? { country: country } : {}),
            },
          }}
        >
          <text
            textAnchor="middle"
            transform="translate(600 121.624)"
            fill="#fff"
            fontSize={16}
            fontFamily="Poppins,sans-serif"
            fontWeight={600}
          >
            <tspan x={-18.56} y={0}>
              {"Agile"}
            </tspan>
          </text>
        </CustomLink>
        <CustomLink
          title="Project Management"
          href={{
            pathname: country
              ? "/[country]/courses/[category]"
              : "/courses/[category]",
            query: {
              category: "project-management",
              ...(country ? { country: country } : {}),
            },
          }}
        >
          <text
            textAnchor="middle"
            data-name="Project Management"
            transform="translate(435 66.624)"
            fill="#fff"
            fontSize={15}
            fontFamily="Poppins,sans-serif"
            fontWeight={600}
          >
            <tspan x={-27.872} y={0}>
              {"Project "}
            </tspan>
            <tspan x={-30.912} y={17}>
              {"Management"}
            </tspan>
          </text>
        </CustomLink>
        <CustomLink
          title="DevOps"
          href={{
            pathname: country
              ? "/[country]/courses/[category]"
              : "/courses/[category]",
            query: {
              category: "devops",
              ...(country ? { country: country } : {}),
            },
          }}
        >
          <text
            textAnchor="middle"
            transform="translate(395 242.624)"
            fill="#fff"
            fontSize={16}
            fontFamily="Poppins,sans-serif"
            fontWeight={600}
          >
            <tspan x={-29.432} y={0}>
              {"DevOps"}
            </tspan>
          </text>
        </CustomLink>
        <CustomLink
          title="Cyber Security"
          href={{
            pathname: country
              ? "/[country]/courses/[category]"
              : "/courses/[category]",
            query: {
              category: "cybersecurity",
              ...(country ? { country: country } : {}),
            },
          }}
        >
          <text
            data-name="IT Security"
            transform="translate(470 177.624)"
            fill="#fff"
            fontSize={11}
            fontFamily="Poppins,sans-serif"
            fontWeight={600}
          >
            <tspan x={-4.372} y={0}>
              {"IT"}
            </tspan>
            <tspan x={-20.724} y={10}>
              {"Security"}
            </tspan>
          </text>
        </CustomLink>
        <CustomLink
          title="ITSM"
          href={{
            pathname: country
              ? "/[country]/courses/[category]"
              : "/courses/[category]",
            query: {
              category: "itsm",
              ...(country ? { country: country } : {}),
            },
          }}
        >
          <text
            textAnchor="middle"
            transform="translate(475 295.624)"
            fill="#fff"
            fontSize={18}
            fontFamily="Poppins,sans-serif"
            fontWeight={600}
          >
            <tspan x={3} y={0}>
              {"ITSM"}
            </tspan>
          </text>
        </CustomLink>
        <CustomLink
          title="Quality Management"
          href={{
            pathname: country
              ? "/[country]/courses/[category]"
              : "/courses/[category]",
            query: {
              category: "quality-management",
              ...(country ? { country: country } : {}),
            },
          }}
        >
          <text
            textAnchor="middle"
            data-name="Quality Management"
            transform="translate(640 236.624)"
            fill="#fff"
            fontSize={15}
            fontFamily="Poppins,sans-serif"
            fontWeight={600}
          >
            <tspan x={-27.048} y={0}>
              {"Quality"}
            </tspan>
            <tspan x={-26.912} y={17}>
              {"Management"}
            </tspan>
          </text>
        </CustomLink>
      </g>
    </svg>
  );
};
const HomeBannerSvg = React.memo(HomeBannerSvgComponent);
export default HomeBannerSvg;
